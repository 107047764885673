import { BaseService } from "./base.service";
import { Page } from "../models/page.model";
import { Officer } from "../models/officer.model";
import { PagedData } from "../models/page-data.model";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ConfigUrl } from "./config-url";

@Injectable({
    providedIn: 'root'
})
export class OfficerService extends BaseService {
    constructor(private http: HttpClient) {
        super();
    }

    //getList(page: Page, searchData: any): Observable<PagedData<Officer>> {
    getList(page: Page, searchData: any): Observable<PagedData<Officer>> {
        let params = new HttpParams();
        params = params.append('page', page.current_page.toString());
        params = params.append('size', page.size_per_page.toString());
        params = this.setParams(params, searchData);
        return this.http.get<PagedData<Officer>>(ConfigUrl.host + '/officer', { params: params });
    }

    getListNoPage(): Observable<PagedData<Officer>> {
        let params = new HttpParams();
        params = params.append('page', "0");
        params = params.append('size', "9999999999");
        return this.http.get<PagedData<Officer>>(ConfigUrl.host + '/officer', { params: params });
    }

    save(data: any) {
        return this.http.post<any>(ConfigUrl.host + '/officer', data);
    }

    update(data: any) {
        return this.http.put<any>(ConfigUrl.host + '/officer/'+data.id, data);
    }

    checkingOfficer(cardId: string, orgCode: string){
        return this.http.post<any>(ConfigUrl.host + '/officer/check', {'card_id': cardId, 'org_code': orgCode});
    }

    checkingOfficerDev(cardId: string, orgCode: string){
        return this.http.post<any>(ConfigUrl.host + '/officer/test_check', {'card_id': cardId, 'org_code': orgCode});
    }

    delete(id: number){
        return this.http.delete<any>(ConfigUrl.host+'/officer/'+id, {});
      }

}
