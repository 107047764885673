import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { ImageResolution } from "../models/image-resolution.model";
import { Page } from "../models/page.model";
import { PagedData } from "../models/page-data.model";
import { ConfigUrl } from "./config-url";

@Injectable({
  providedIn: 'root'
})
export class configOffenderService extends BaseService {
  imageRes: ImageResolution[] = [];

  constructor(
    private http: HttpClient
  ) {
    super();
  }

  getList(page: Page) {
    let params = new HttpParams();
    params = params.append('page', "0");
    params = params.append('size', "9999999999");
    return this.http.get<PagedData<ImageResolution>>(ConfigUrl.host + '/offender/config', { params: params });
  }


  update(id : any, data : any){
    return this.http.put<any>(ConfigUrl.host + '/offender/config/'+id, data)
  }
}