import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Page } from "../models/page.model";
import { PagedData } from "../models/page-data.model";
import { ConfigUrl } from "./config-url";

@Injectable({
  providedIn: 'root'
})
export class DashboardService extends BaseService {
  

  constructor(
    private http: HttpClient
  ) {
    super();
  }

  getList(searchData:any) {
    let params = new HttpParams();
    params = this.setParams(params, searchData);
    return this.http.get(ConfigUrl.host + '/dashboard/report', { params: params })
  }

}