import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Role } from '../models/role.model';
import { ConfigUrl } from './config-url';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  private _roles: Role[] = [];
  roleChanged = new Subject<Role[]>();

  constructor(
    private http: HttpClient
  ) { }

  getRoles() {
    return this.http.get<Role[]>(ConfigUrl.host + '/role').pipe(map(
      (roles: any) => {
        return roles.items;
      }
    )).subscribe(
      (roles: Role[]) => {
        this.setRoles(roles);
      }
    );
  }

  setRoles(roles: Role[]) {
    this._roles = roles;
    this.roleChanged.next(this._roles.slice());
  }

  addOrEdit(role: Role){
    return this.http.post<Role>(ConfigUrl.host+'/role', role);
  }

}
